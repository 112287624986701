import {faXmark} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useTranslation} from 'next-i18next'
import React from 'react'

interface ServicePointFilterButtonProps {
  isFilterOpen: boolean
  numberOfFiltersApplied: number
  onFilterClicked: () => void
}

function ServicePointFilterButton({
  isFilterOpen,
  numberOfFiltersApplied,
  onFilterClicked,
}: ServicePointFilterButtonProps) {
  const {t} = useTranslation()

  return (
    <div className="flex h-[45px] rounded-dats">
      {isFilterOpen ? (
        <div className="hover:bg-slate-100 hidden w-[48px] items-center justify-center rounded-dats bg-white p-2 shadow-dats hover:cursor-pointer md:flex">
          <FontAwesomeIcon
            icon={faXmark}
            style={{color: '#005E75'}}
            width={20}
            height={20}
          />
        </div>
      ) : (
        <button
          onClick={onFilterClicked}
          className="hover:bg-slate-100 rounded-dats bg-white p-2 shadow-dats"
        >
          <div className="flex items-center justify-between space-x-[10px] px-2">
            <div>
              {numberOfFiltersApplied > 0 ? (
                <div className="absolute z-10 mx-auto -mt-1 ml-2 size-4 rounded-full bg-dats-p2 align-middle">
                  <p className="text-[10px] text-white">
                    {numberOfFiltersApplied}
                  </p>
                </div>
              ) : null}

              <svg
                width="20"
                height="20"
                viewBox="0 0 13 13"
                className="fill-dats-s3"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.4431 0.987061C11.9541 0.987061 12.2096 1.59099 11.838 1.93941L7.56399 6.23661V12.3224C7.56399 12.7869 7.05297 13.0425 6.68132 12.7869L4.82307 11.4862C4.66047 11.3933 4.59079 11.2074 4.59079 11.0216V6.23661L0.293589 1.93941C-0.078061 1.59099 0.177448 0.987061 0.688467 0.987061H11.4431Z" />
              </svg>
            </div>
            <p className="hidden font-body text-[16px] font-semibold text-dats-s3 md:block">
              {t('label_service_point_locator_filters')}
            </p>
          </div>
        </button>
      )}
    </div>
  )
}

export default ServicePointFilterButton
